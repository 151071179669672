import onInitModulesInScope from "../scripts/onInitModulesInScope";

// import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import * as tracking from './tracking';
onInitModulesInScope(tracking.initInScope);

import {initGtmEventTracking} from "./genericTracking";
initGtmEventTracking();

import * as trackingGa4 from './tracking-ga4';
let ga4Options = {
    debug: true
}; // optional options

onInitModulesInScope(trackingGa4.initGtmEventTracking(ga4Options));

import * as trackingCustom from './tracking-custom';
onInitModulesInScope(trackingCustom.initInScope);

import * as trackingErrorMessages from './tracking-error-messages';
trackingErrorMessages.init();


import * as accordion from './accordion';
onInitModulesInScope(accordion.initInScope);

import * as lazyImg from '@elements/lazy-img';
onInitModulesInScope(lazyImg.initInScope);

import * as ajaxForm from '@elements/ajax-form';
onInitModulesInScope(ajaxForm.createInitInScope({
    submitOnChange: true
}));

import * as filterCounter from './filter-counter';
onInitModulesInScope(filterCounter.initInScope);

import * as productGrid from './product-grid/product-grid';
productGrid.init();
onInitModulesInScope(productGrid.initInScope);

import * as productGridQuickFilter from './product-grid/quick-filter';
onInitModulesInScope(productGridQuickFilter.initInScope);

import * as productTeaser from './product-teaser';
onInitModulesInScope(productTeaser.initInScope);

import * as videoJs from './videoJS';
onInitModulesInScope(videoJs.initInScope);

import {closeOverlay} from "./overlay";
// close overlay when product grid response is successful
$('.js-product-grid').on('product-grid.success', function(){
    closeOverlay($('#productGridOverlay'));
});

import * as affix from '@elements/affix';
onInitModulesInScope(affix.createInitInScope({
        breakpoints: {
            768: {
                offset: 60
            },
        }
    },
    {
        base: '.js-affix-filter',
        placeholder: '.js-affix-filter__placeholder',
        container: '.js-affix-filter__container'
    }));

import *as hashCash from './hashCash';

import * as goToLink from '@elements/go-to-link';
goToLink.init();

import * as consentOverlay from './cookie-consent-overlay';
onInitModulesInScope(consentOverlay.initInScope);

import * as ajaxInclude from './ajaxInclude';
onInitModulesInScope(ajaxInclude.initInScope);

import * as parsley from '@elements/parsley-bootstrap-validation';
onInitModulesInScope(parsley.initInScope);

import * as toc from '@elements/toc';
toc.init();
onInitModulesInScope(toc.initInScope);

import * as navbarToggle from './navbar-toggle';
onInitModulesInScope(navbarToggle.initInScope);

import * as meiliSearch from './meili-search';
meiliSearch.init();

import * as affixNav from './affix-nav';
onInitModulesInScope(affixNav.initInScope);

import * as floatingLabel from '@elements/floating-labels';
onInitModulesInScope(floatingLabel.initInScope);

import * as lightbox from '@elements/lightbox';
onInitModulesInScope(lightbox.initInScope);

import * as toggleClick from './toggle-click';
onInitModulesInScope(toggleClick.initInScope);

import * as meiliSearchTypeahead from './search-typeahead';
meiliSearchTypeahead.initInScope();

import * as tooltip from './tooltip';
onInitModulesInScope(tooltip.initInScope);

import * as countrySelectModal from './country-select-modal';
onInitModulesInScope(countrySelectModal.initInScope);

import * as countrySuggestionModal from './country-suggestion-modal';
onInitModulesInScope(countrySuggestionModal.initInScope);

import * as informationModal from './information-modal';
onInitModulesInScope(informationModal.initInScope);

import * as overlay from './overlay';
onInitModulesInScope(overlay.initInScope);

import * as numberSpinner from "@elements/number-spinner";
onInitModulesInScope(numberSpinner.initInScope);

import * as footerCollapse from "./footer-collapse";
onInitModulesInScope(footerCollapse.initInScope);

import * as renderTemplate from './render-template';
onInitModulesInScope(renderTemplate.initInScope);

import * as menuAim from './menu-aim';
onInitModulesInScope(menuAim.initInScope);

import * as collapsibleBreadcrumb from './collapsible-breadcrumb';
collapsibleBreadcrumb.init();

let selectors = {
    link: '.js-smoothscroll__link'
};
import * as smoothScroll from '@elements/smooth-scroll';
smoothScroll.init({
    offset: 100
},selectors);
onInitModulesInScope(smoothScroll.initInScope);

import * as lottieAnimation from './lottie-animation';
lottieAnimation.init();

import * as filterDropdown from './filter-form/filter-dropdown';
onInitModulesInScope(filterDropdown.initInScope);

import * as cart from './cart';
cart.init();
onInitModulesInScope(cart.initInScope);

import  * as wishlist from './wishlist';
onInitModulesInScope(wishlist.initInScope);

import * as wishlistRequest from './wishlist-request';
onInitModulesInScope(wishlistRequest.init);
onInitModulesInScope(wishlistRequest.initInScope);
